@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
$fontstack: 'Roboto Mono', 'Helvetica', sans-serif;
$darkteal: rgb(8, 59, 61);
$lightcolour: #f5f5f5;

/* Small phone portrait */

@media only screen and (min-width: 1px) and (max-width: 349px) and (orientation: portrait) {

    body {
        background-image: url("and-machines-ceCISmZkDXg-unsplash.jpg");
        background-attachment: fixed;
        font-family: $fontstack;
        margin-top: 100px;
        margin-bottom: 100px;
        margin-left: 0px;
    }
    
    .headerFrame {
        z-index: 1;
        padding: 10px 0px 0px 0px;
        position: fixed;
        height: 70px;
        top: 0px;
        left: 0px;
        right: 0px;
        margin-top: 0px;
        background-color: $lightcolour;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            color: $darkteal;
            font-size: 20px;
            margin: 10px;
        }
    }

    .navigationRow {
        display: flex;
        align-items: center;
        gap: 3vw;
        margin-top: 2vw;
    }

    .footerFrame {
        padding: 10px;
        height: 100px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin-bottom: 0px;
        border-radius: 3px;
        background-color: $lightcolour;
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        p {
            color: $darkteal;
            font-size: 16px;
            margin: 10px;
        }
        .linksFooter {
            margin-left: 10px;
            margin-bottom: 15px;
            display: flex;
            gap: 10px;
            .linkedIn {
                #icon {
                    filter: brightness(0) saturate(100%) invert(33%) sepia(73%) saturate(1281%) hue-rotate(173deg) brightness(87%) contrast(102%);
                    width: 30px;
                }
            }
            .instagram {
                
                #icon {
                    width: 30px;
                }
            }

            .github {
                #icon {
                    width: 30px;
                }
            }
        }
    }

    .button--email {
        background-color: $lightcolour;
        padding: 10px;
        font-size: 18px;
        border: 1px solid $lightcolour;
        color: $darkteal;
        font-family: $fontstack;
    
        &:hover{
            border: 1px solid rgb(167, 167, 167);
        }
    }

    .button--navigate {
        background-color: rgb(198, 230, 224);
        text-transform: uppercase;
        border-radius: 3px;
        border: 1px solid $lightcolour;
        font-family: $fontstack;
        padding: 4px;
        font-size: 12px;
        color: $darkteal;
    
        &:hover{
            border: 1px solid rgb(167, 167, 167);
        }
    }

    .button--title {
        color: $darkteal;
        font-size: 20px;
        background-color: $lightcolour;
        font-family: $fontstack;
        border: none;
    }

    .mainContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .mainFrame {
        margin-top: auto;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0vw 6vw 0vw 6vw;
        .subText{
            color: $lightcolour;
        }

        .outerFrame {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            p {
                color: $lightcolour;
                font-size: 20px;
                margin: 0px;
            }
        }
    }

    .cardsPortfolio {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
        .card {
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            transition: 0.3s;
            width: 80vw;
            img {
                width: 80vw;
            }
        }
    
        .card:hover {
            box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
            transform: translateY(-5px);
        } 
        .container {
            background-color: $lightcolour;
            padding: 3px 20px;
        }
    }

    .profilePicture {
        position: relative;
        height: 255.13px;
        img {
            width: 200px;
            height: auto;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
    }

    .profilePicture:hover .overProfile {
        height: 100%;
      }

    .overProfile {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(245, 245, 245, 0.6);
        overflow: hidden;
        width: 100%;
        height: 0;
        transition: .6s ease;
        p {
            padding: 8px;
            font-size: 14px;
            font-weight:600;
            a {
                text-decoration: none;
                color: $darkteal;
            }
        }
      }

    .sendEmail{
        margin-top: 20px;
    }

    .outerFrame{
        margin: 20px 20px 0px 20px;
    }
}

/* Small phone landscape */



/* Big phone portrait */

@media only screen and (min-device-width: 350px) and (max-device-width: 425px) and (orientation: portrait) {

    body {
        background-image: url("and-machines-ceCISmZkDXg-unsplash.jpg");
        background-attachment: fixed;
        font-family: $fontstack;
        margin-top: 100px;
        margin-bottom: 100px;
        margin-left: 0px;
    }
    
    .headerFrame {
        z-index: 1;
        padding: 10px 0px 0px 0px;
        position: fixed;
        height: 100px;
        top: 0px;
        left: 0px;
        right: 0px;
        margin-top: 0px;
        background-color: $lightcolour;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .navigationRow {
        display: flex;
        align-items: center;
        gap: 3vw;
        margin-top: 2vw;
    }

    .footerFrame {
        padding: 10px;
        height: 100px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin-bottom: 0px;
        border-radius: 3px;
        background-color: $lightcolour;
        opacity: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        p {
            color: $darkteal;
            font-size: 16px;
            margin: 10px;
        }
        .linksFooter {
            margin-left: 10px;
            margin-bottom: 15px;
            display: flex;
            gap: 10px;
            .linkedIn {
                #icon {
                    filter: brightness(0) saturate(100%) invert(33%) sepia(73%) saturate(1281%) hue-rotate(173deg) brightness(87%) contrast(102%);
                    width: 30px;
                }
            }
            .instagram {
                
                #icon {
                    width: 30px;
                }
            }

            .github {
                #icon {
                    width: 30px;
                }
            }
        }
    }

    .button--email {
        background-color: $lightcolour;
        padding: 10px;
        font-size: 18px;
        border: 1px solid $lightcolour;
        color: $darkteal;
        font-family: $fontstack;
    
        &:hover{
            border: 1px solid rgb(167, 167, 167);
        }
    }

    .button--navigate {
        background-color: rgb(198, 230, 224);
        text-transform: uppercase;
        border-radius: 3px;
        border: 1px solid $lightcolour;
        font-family: $fontstack;
        padding: 5px;
        font-size: 15px;
        color: $darkteal;
    
        &:hover{
            border: 1px solid rgb(167, 167, 167);
        }
    }

    .button--title {
        color: $darkteal;
        font-size: 30px;
        background-color: $lightcolour;
        font-family: $fontstack;
        border: none;
    }

    .mainContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .mainFrame {
        margin-top: 40px;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0vw 6vw 0vw 6vw;
        .subText{
            color: $lightcolour;
        }

        .outerFrame {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            p {
                color: $lightcolour;
                font-size: 20px;
                margin: 0px;
            }
        }
    }

    .cardsPortfolio {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
        .card {
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            transition: 0.3s;
            width: 80vw;
            img {
                width: 80vw;
            }
        }
    
        .card:hover {
            box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
            transform: translateY(-5px);
        } 
        .container {
            background-color: $lightcolour;
            padding: 3px 20px;
        }
    }

    .profilePicture {
        position: relative;
        height: 255.13px;
        img {
            width: 200px;
            height: auto;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
    }

    .profilePicture:hover .overProfile {
        height: 100%;
      }

    .overProfile {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(245, 245, 245, 0.6);
        overflow: hidden;
        width: 100%;
        height: 0;
        transition: .6s ease;
        p {
            padding: 8px;
            font-size: 14px;
            font-weight:600;
            a {
                text-decoration: none;
                color: $darkteal;
            }
        }
      }

    .sendEmail{
        margin-top: 20px;
    }

    .outerFrame{
        margin: 20px 20px 0px 20px;
    }
}

/* Big phone landscape */

@media only screen and (min-device-width: 1px) and (max-device-width: 900px) and (orientation: landscape) {
    body {
        background-image: url("and-machines-ceCISmZkDXg-unsplash.jpg");
        background-attachment: fixed;
        font-family: $fontstack;
        margin-top: 100px;
        margin-bottom: 100px;
        margin-left: 0px;
    }
    
    .headerFrame {
        z-index: 1;
        padding: 10px 0px 0px 0px;
        position: fixed;
        height: 50px;
        top: 0px;
        left: 0px;
        right: 0px;
        margin-top: 0px;
        background-color: $lightcolour;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .navigationRow {
        display: flex;
        align-items: center;
        gap: 3vw;
    }

    .footerFrame {
        padding: 10px;
        height: 40px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin-bottom: 0px;
        background-color: $lightcolour;
        opacity: 1;
        display: flex;
        justify-content: space-around;
        p {
            color: $darkteal;
            font-size: 16px;
            margin: 10px;
        }
        .linksFooter {
            margin-left: 10px;
            margin-bottom: 15px;
            display: flex;
            gap: 10px;
            .linkedIn {
                #icon {
                    filter: brightness(0) saturate(100%) invert(33%) sepia(73%) saturate(1281%) hue-rotate(173deg) brightness(87%) contrast(102%);
                    width: 30px;
                }
            }
            .instagram {
                
                #icon {
                    width: 30px;
                }
            }

            .github {
                #icon {
                    width: 30px;
                }
            }
        }
    }

    .button--email {
        background-color: $lightcolour;
        padding: 10px;
        font-size: 18px;
        border: 1px solid $lightcolour;
        color: $darkteal;
        font-family: $fontstack;
    
        &:hover{
            border: 1px solid rgb(167, 167, 167);
        }
    }

    .button--navigate {
        background-color: rgb(198, 230, 224);
        text-transform: uppercase;
        border-radius: 3px;
        border: 1px solid $lightcolour;
        font-family: $fontstack;
        padding: 5px;
        font-size: 15px;
        color: $darkteal;
    
        &:hover{
            border: 1px solid rgb(167, 167, 167);
        }
    }

    .button--title {
        color: $darkteal;
        font-size: 30px;
        background-color: $lightcolour;
        font-family: $fontstack;
        border: none;
    }

    .mainContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .mainFrame {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0vw 6vw 0vw 6vw;
        .subText{
            color: $lightcolour;
        }

        .outerFrame {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            p {
                color: $lightcolour;
                font-size: 20px;
                margin: 0px;
            }
        }
    }

    .cardsPortfolio {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
        .card {
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            transition: 0.3s;
            width: 60vw;
            img {
                width: 60vw;
            }
        }
    
        .card:hover {
            box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
            transform: translateY(-5px);
        } 
        .container {
            background-color: $lightcolour;
            padding: 3px 20px;
        }
    }

    .profilePicture {
        position: relative;
        height: 255.13px;
        img {
            width: 200px;
            height: auto;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
    }

    .profilePicture:hover .overProfile {
        height: 100%;
      }

    .overProfile {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(245, 245, 245, 0.6);
        overflow: hidden;
        width: 100%;
        height: 0;
        transition: .6s ease;
        p {
            padding: 8px;
            font-size: 14px;
            font-weight:600;
            a {
                text-decoration: none;
                color: $darkteal;
            }
        }
      }

    .sendEmail{
        margin-top: 20px;
    }

    .outerFrame{
        margin: 20px 20px 0px 20px;
    }
}

/* Tablet portrait */

@media only screen and (min-width: 426px) and (max-width: 819px) and (orientation: portrait) {
    body {
        background-image: url("and-machines-ceCISmZkDXg-unsplash.jpg");
        background-attachment: fixed;
        font-family: $fontstack;
        margin-top: 100px;
        margin-bottom: 100px;
        margin-left: 0px;
    }
    
    .headerFrame {
        z-index: 1;
        padding: 10px;
        position: fixed;
        height: 100px;
        top: 0px;
        left: 0px;
        right: 0px;
        margin-top: 0px;
        width: 100%;
        background-color: $lightcolour;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            color: $darkteal;
            font-size: 40px;
            margin: 10px;
        }
    }

    .navigationRow {
        display: flex;
        gap: 15px;
        padding: 0px 20px 0px 20px;
    }

    .footerFrame {
        padding: 10px;
        height: 100px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin-bottom: 0px;
        border-radius: 3px;
        background-color: $lightcolour;
        opacity: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        p {
            color: $darkteal;
            font-size: 16px;
            margin: 10px;
        }
        .linksFooter {
            margin-left: 10px;
            margin-bottom: 15px;
            display: flex;
            gap: 10px;
            .linkedIn {
                #icon {
                    filter: brightness(0) saturate(100%) invert(33%) sepia(73%) saturate(1281%) hue-rotate(173deg) brightness(87%) contrast(102%);
                    width: 30px;
                }
            }
            .instagram {
                
                #icon {
                    width: 30px;
                }
            }

            .github {
                #icon {
                    width: 30px;
                }
            }
        }
    }

    .button--navigate {
        background-color: rgb(198, 230, 224);
        text-transform: uppercase;
        border-radius: 3px;
        border: 1px solid $lightcolour;
        padding: 10px;
        font-family: $fontstack;
        font-size: 18px;
        color: $darkteal;
    
        &:hover{
            border: 1px solid rgb(167, 167, 167);
        }
    }

    .button--title {
        color: $darkteal;
        font-size: 40px;
        background-color: $lightcolour;
        font-family: $fontstack;
        border: none;
    }

    .button--email {
        background-color: $lightcolour;
        padding: 10px;
        font-size: 18px;
        border: 1px solid $lightcolour;
        color: $darkteal;
        font-family: $fontstack;
    
        &:hover{
            border: 1px solid rgb(167, 167, 167);
        }
    }

    .mainContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .mainFrame {
        margin-top: 40px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .subText {
            color: $lightcolour;
        }
    }

    .profilePicture {
        position: relative;
        height: 331.7px;
        img {
            width: 260px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
    }

    .profilePicture:hover .overProfile {
        height: 100%;
      }

    .overProfile {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(245, 245, 245, 0.6);
        overflow: hidden;
        width: 100%;
        height: 0;
        transition: .6s ease;
        p {
            padding: 15px;
            font-size: 18px;
            font-weight:600;
            a {
                text-decoration: none;
                color: $darkteal;
            }
        }
      }

    .sendEmail{
        margin-top: 20px;
    }

    .outerFrame {
        padding: 0px 20px 0px 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        p {
            color: $lightcolour;
            font-size: 20px;
            margin: 20px 20px 0px 20px;
        }
    }

    .cardsPortfolio {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
        .card {
            width: 300px;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            transition: 0.3s;
            img {
                width: 300px;
            }
        }

        .card:hover {
            box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
            transform: translateY(-5px);
        } 
        .container {
            background-color: $lightcolour;
            padding: 3px 20px;
            height: auto;
        }
    }
}

/* Tablet landscape */

/* Desktop small */

@media only screen and (min-width: 820px) and (max-width: 1024px) {
    body {
        background-image: url("and-machines-ceCISmZkDXg-unsplash.jpg");
        background-attachment: fixed;
        font-family: $fontstack;
        margin-top: 70px;
        margin-bottom: 70px;
        margin-left: 0px;
    }
    
    .headerFrame {
        z-index: 1;
        padding: 10px;
        position: fixed;
        height: 70px;
        top: 0px;
        left: 0px;
        right: 0px;
        margin-top: 0px;
        width: 100%;
        background-color: $lightcolour;
        opacity: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            color: $darkteal;
            font-size: 40px;
            margin: 10px;
        }
    }
    
    .footerFrame {
        padding: 10px;
        height: 70px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin-bottom: 0px;
        border-radius: 3px;
        background-color: $lightcolour;
        opacity: 1;
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        p {
            color: $darkteal;
            font-size: 30px;
            margin: 10px;
        }
        .linksFooter {
            margin-left: 10px;
            display: flex;
            gap: 10px;
            .linkedIn {
                #icon {
                    filter: brightness(0) saturate(100%) invert(33%) sepia(73%) saturate(1281%) hue-rotate(173deg) brightness(87%) contrast(102%);
                    width: 30px;
                }
            }
            .instagram {
                
                #icon {
                    width: 30px;
                }
            }

            .github {
                #icon {
                    width: 30px;
                }
            }
        }
        
    }
    
    .button--navigate {
        background-color: rgb(198, 230, 224);
        text-transform: uppercase;
        border-radius: 3px;
        border: 1px solid $lightcolour;
        padding: 10px;
        font-family: $fontstack;
        font-size: 18px;
        color: $darkteal;
    
        &:hover{
            border: 1px solid rgb(167, 167, 167);
        }
    }
    
    .button--title {
        color: $darkteal;
        font-size: 40px;
        background-color: $lightcolour;
        font-family: $fontstack;
        border: none;
    }
    
    .button--email {
        background-color: $lightcolour;
        padding: 10px;
        font-size: 18px;
        border: 1px solid $lightcolour;
        color: $darkteal;
        font-family: $fontstack;
    
        &:hover{
            border: 1px solid rgb(167, 167, 167);
        }
    }
    
    .navigationHeader {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
    
    .navigationRow {
        display: flex;
        gap: 15px;
        padding: 0px 20px 0px 20px;
    }
    
    .mainContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 750px;
    }
    
    .mainFrame {
        display: flex;
        margin-top: 40px;
        flex-direction: column;
        border-radius: 3px;
        align-items: center;
        justify-content: center;

        .subText{
            color: $lightcolour;
            padding: 0px 40px 0px 40px;
        }
    }
    
    .profilePicture {
        position: relative;
        height: 382.7px;
        img {
            width: 300px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
    }

    .profilePicture:hover .overProfile {
        height: 100%;
      }

    .overProfile {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(245, 245, 245, 0.6);
        overflow: hidden;
        width: 100%;
        height: 0;
        transition: .6s ease;
        p {
            padding: 20px;
            font-size: 20px;
            font-weight:600;
            a {
                text-decoration: none;
                color: $darkteal;
            }
        }
      }
    
    .outerFrame {
        padding: 0px 20px 0px 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        flex-grow: 1;
        p {
            color: $lightcolour;
            font-size: 20px;
            margin: 20px 20px 0px 20px;
        }
    }

    .sendEmail{
        margin-top: 20px;
    }

    .cardsPortfolio {
        display: flex;
        gap: 4vw;
        margin-top: 30px;

        .card {
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            transition: 0.3s;
            height: fit-content;
            width: 220px;
            img {
                width: 220px;
            }
        }

        .card:hover {
            box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
            transform: translateY(-5px);
        } 
        .container {
            background-color: $lightcolour;
            padding: 3px 20px;
        }
    }
}

/* Desktop large */

@media only screen and (min-width: 1025px) {
    body {
        background-image: url("and-machines-ceCISmZkDXg-unsplash.jpg");
        background-attachment: fixed;
        font-family: $fontstack;
        margin-top: 70px;
        margin-bottom: 70px;
        margin-left: 0px;
    }
    
    .headerFrame {
        z-index: 1;
        padding: 10px;
        position: fixed;
        height: 70px;
        top: 0px;
        left: 0px;
        right: 0px;
        margin-top: 0px;
        width: 100%;
        background-color: $lightcolour;
        opacity: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            color: $darkteal;
            font-size: 40px;
            margin: 10px;
        }
    }
    
    .footerFrame {
        padding: 10px;
        height: 70px;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin-bottom: 0px;
        border-radius: 3px;
        background-color: $lightcolour;
        opacity: 1;
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        p {
            color: $darkteal;
            font-size: 30px;
            margin: 10px;
        }
        .linksFooter {
            margin-left: 10px;
            display: flex;
            gap: 10px;
            .linkedIn {
                #icon {
                    filter: brightness(0) saturate(100%) invert(33%) sepia(73%) saturate(1281%) hue-rotate(173deg) brightness(87%) contrast(102%);
                    width: 30px;
                }
            }
            .instagram {
                
                #icon {
                    width: 30px;
                }
            }

            .github {
                #icon {
                    width: 30px;
                }
            }
        }
        
    }
    
    .button--navigate {
        background-color: rgb(198, 230, 224);
        text-transform: uppercase;
        border-radius: 3px;
        border: 1px solid $lightcolour;
        padding: 10px;
        font-family: $fontstack;
        font-size: 18px;
        color: $darkteal;
    
        &:hover{
            border: 1px solid rgb(167, 167, 167);
        }
    }
    
    .button--title {
        color: $darkteal;
        font-size: 40px;
        background-color: $lightcolour;
        font-family: $fontstack;
        border: none;
    }
    
    .button--email {
        background-color: $lightcolour;
        padding: 10px;
        font-size: 18px;
        border: 1px solid $lightcolour;
        color: $darkteal;
        font-family: $fontstack;
    
        &:hover{
            border: 1px solid rgb(167, 167, 167);
        }
    }
    
    .navigationHeader {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
    
    .navigationRow {
        display: flex;
        gap: 15px;
        padding: 0px 20px 0px 20px;
    }
    
    .mainContainer {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    
    .mainFrame {
        display: flex;
        margin-top: 40px;
        flex-direction: column;
        border-radius: 3px;
        align-items: center;
        justify-content: center;

        .subText{
            color: $lightcolour;
            padding: 0px 40px 0px 40px;
        }
    }
    
    .profilePicture {
        position: relative;
        height:382.7px;
        img {
            width: 300px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            transition: 0.5s;
        }
    }

    .profilePicture:hover .overProfile {
        height: 100%;
      }

    .overProfile {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(245, 245, 245, 0.6);
        overflow: hidden;
        width: 100%;
        height: 0;
        transition: .6s ease;
        p {
            padding: 20px;
            font-size: 20px;
            font-weight:600;
            a {
                text-decoration: none;
                color: $darkteal;
            }
        }
      }
    
    .outerFrame {
        padding: 0px 20px 0px 20px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        flex-grow: 1;
        p {
            color: $lightcolour;
            font-size: 20px;
            margin: 20px 20px 0px 20px;
        }
    }

    .sendEmail{
        margin-top: 20px;
    }

    .cardsPortfolio {
        display: flex;
        gap: 4vw;
        margin-top: 30px;

        .card {
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
            transition: 0.3s;
            height: fit-content;
            width: 20vw;
            img {
                width: 20vw;
            }
        }

        .card:hover {
            box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
            transform: translateY(-5px);
        } 
        .container {
            background-color: $lightcolour;
            padding: 3px 20px;
        }
    }
}




